
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import type { SurveyTemplateType } from '@/definitions/services/smsSurvey/types'

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  setup() {
    const name = ref()
    const selectedTemplateId = ref<string | null>(null)
    const templates = ref<SurveyTemplateType[]>([
      {
        id: '1',
        icon: 'call_split',
        iconRotate: 180,
        name: 'Blank template',
        text: 'Start building your survey from scratch.',
      },
      {
        id: '2',
        icon: 'tmi-message-draw',
        name: 'Customer feedback',
        text: 'Find out how customers like your product.',
      },
      {
        id: '3',
        icon: 'tmi-people',
        name: 'Team performance',
        text: 'Find out how effective is your team performance.',
      },
      {
        id: '4',
        icon: 'thumb_up',
        name: 'Brand awareness',
        text: 'Find out how people treat your brand name.',
      },
      {
        id: '5',
        icon: 'event',
        name: 'Event planning',
        text: 'Get some insight into yuor upcoming event.',
      },
      {
        id: '6',
        icon: 'event_available',
        name: 'Event feedback',
        text: 'Find out how successful was your past event.',
      },
    ])
    return {
      name,
      selectedTemplateId,
      templates,
    }
  },
})
